<form [formGroup]="aboutYouComponentForm" class="flex flex-col gap-400 self-start md:[grid-area:content]" (submit)="handleSubmit($event)" id="quote-form">
  <app-form-card [title]="'We\'ll need some personal information to build your quote.'" icon="lucideCircleUserRound">
    <ng-template #subcopyTemplate>
      <p>Already started a quote with Kin? <button (click)="navigateToResumePage()" class="link font-bold" data-testid="route-to-resume">Pick up where you left off</button></p>
    </ng-template>

    <div class="mt-600 grid grid-cols-1 gap-x-400 gap-y-400 sm:grid-cols-6 md:gap-y-600">
      <app-text-input
        class="sm:col-span-3"
        [control]="aboutYouComponentForm.controls.firstName"
        label="First name"
        id="first-name"
        data-testid="first-name"
        [required]="true"
        [errorMessages]="errorMessages.firstName"
      ></app-text-input>

      <app-text-input
        class="sm:col-span-3"
        [control]="aboutYouComponentForm.controls.lastName"
        label="Last name"
        id="last-name"
        data-testid="last-name"
        [required]="true"
        [errorMessages]="errorMessages.lastName"
      ></app-text-input>

      <app-text-input
        class="sm:col-span-6"
        [control]="aboutYouComponentForm.controls.dob"
        label="Date of birth"
        [maskito]="maskitoDateOptions"
        inputmode="numeric"
        placeholder="MM/DD/YYYY"
        id="dob"
        data-testid="birthdate"
        autocomplete="birthday"
        type="text"
        [required]="true"
        [errorMessages]="errorMessages.dob"
        helpText="We need your birth date to get your insurance score for the most accurate quote."
      ></app-text-input>

      <app-text-input
        class="sm:col-span-3"
        [control]="aboutYouComponentForm.controls.phoneNumber"
        label="Phone"
        id="phone"
        data-testid="phone"
        type="tel"
        placeholder="(   )    -    "
        [maskito]="maskitoPhoneOptions"
        [required]="true"
        [errorMessages]="errorMessages.phoneNumber"
        helpText="We'll use this to contact you about your policy."
      ></app-text-input>

      <app-text-input
        [control]="aboutYouComponentForm.controls.email"
        class="sm:col-span-3"
        label="Email"
        id="email"
        data-testid="email"
        type="email"
        [required]="true"
        [errorMessages]="errorMessages.email"
        helpText="This will be used to create your account."
      ></app-text-input>
    </div>
  </app-form-card>
  <app-form-card>
    <label class="checkbox mb-400" for="sms-opt-in">
      <input class="checkbox__control" type="checkbox" value="yes" id="sms-opt-in" data-testid="sms-checkbox" [formControl]="aboutYouComponentForm.controls.hasOptedIntoSms" />

      <span class="checkbox__label font-regular"> I agree to receive SMS texts for Kin Insurance Group promotional purposes (Optional) </span>
    </label>

    <label class="checkbox mb-400" for="tcpa-consent">
      <input class="checkbox__control" type="checkbox" value="yes" id="tcpa-consent" data-testid="tcpa-consent-checkbox" [formControl]="aboutYouComponentForm.controls.hasGivenTcpaConsent" />

      <span class="checkbox__label font-regular" id="tcpa-consent-text">
        @if (aboutYouComponentForm.controls.hasGivenTcpaConsent.errors && aboutYouComponentForm.controls.hasGivenTcpaConsent.touched) {
          <div data-testid="tcpa-consent-error-message" class="error-message form-help--invalid flex items-center gap-100" id="tcpa-consent-error">
            <ng-icon name="lucideCircleAlert" class="!overflow-visible" aria-hidden="true"></ng-icon>
            <span class="text-warning">Please check the box to continue</span><br />
          </div>
        }
        I agree to the
        <a href="https://www.kin.com/terms" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">Kin Terms of Service</a>
        and
        <a href="https://www.kin.com/privacy" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">Privacy Policy</a>
        and I authorize Kin Insurance, Inc. and its affiliates ("Kin Insurance Group") to obtain a credit-based insurance score for myself and any co-applicant(s), which may be used for underwriting
        or rating purposes, and <strong>will not affect credit score</strong>.
        @if (isFloridaResident()) {
          <span data-testid="fl-content"
            >The Department of Financial Services offers free financial literacy programs to assist you with insurance-related questions, including how credit works and how credit scores are
            calculated. To learn more, visit
            <a href="https://www.myfloridacfo.com" target="_blank" rel="noopener noreferrer" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"> www.MyFloridaCFO.com </a
            >.
          </span>
        }
        I authorize Kin Insurance Group to contact me at the email and/or phone number I provided for marketing purposes, including using an automatic telephone dialing system and/or prerecorded voice
        even if my number is on a Do Not Call list. I acknowledge that I can revoke consent at any time by contacting Kin at {{ csPhone | phone }}, or
        <a href="mailto:support@kin.com" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">support&#64;kin.com</a>. Consent to receiving automated calls or SMS texts is
        not a condition of purchase.
      </span>
    </label>

    <p class="text-50 font-normal leading-[1.3] text-neutral-800">
      * For properties located outside of Florida or Louisiana, Kin "homeowners insurance" and "home insurance" refers to the
      <a href="https://www.kin.com/home-insurance/house-and-property-insurance/" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"
        >Kin House & Property policy</a
      >
      with the Owner-Occupied Endorsement added. The base Kin House & Property policy, the
      <a href="https://www.kin.com/home-insurance/house-and-property-insurance/" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">HD3 policy</a>,
      provides the coverages for customers who rent out their home to others, similar to a
      <a href="https://www.kin.com/blog/dp3-policy/" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">DP3 policy</a>. For customers who live in their
      home full time or part time, the Owner-Occupied Endorsement is added to provide the coverages of a typical homeowners insurance policy, which is similar to a
      <a href="https://www.kin.com/glossary/ho3-policy/" target="_blank" class="underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary">HO3 policy</a>.
    </p>
  </app-form-card>
</form>

<app-form-card class="self-start md:[grid-area:end-rail]">
  <div class="flex flex-col gap-200">
    <h2 class="flex flex-col gap-300 text-100 font-bold leading-[1.375]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="var(--color-primary)"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        class="h-[2em] w-[2em]"
        viewBox="0 0 24 24"
      >
        <path d="M15 14c.2-1 .7-1.7 1.5-2.5 1-.9 1.5-2.2 1.5-3.5A6 6 0 0 0 6 8c0 1 .2 2.2 1.5 3.5.7.7 1.3 1.5 1.5 2.5M9 18h6M10 22h4" />
      </svg>
      What to expect
    </h2>
    <ol class="flex list-inside list-decimal flex-col gap-300">
      <li>We'll ask a <strong>handful of questions</strong> about you and your home.</li>
      <li>We'll <strong>gather additional info about your home</strong> from our trusted sources.</li>
      <li>See your <strong>personalized online quote!</strong></li>
    </ol>
  </div>
</app-form-card>
